.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.language-select-container {
  position: relative;
  font-size: 0.75rem;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  flex-direction: row;
  background-color: #2e4159;
  max-width: 100%;
  padding: 0 21px;
  margin-left: -0.5rem;
}
.language-select-container > .nav-item:first-child {
  position: relative;
  padding-right: 7px;
  margin-right: 5px;
}
.language-select-container > .nav-item:first-child::after {
  content: "";
  display: block;
  width: 2px;
  height: 14px;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -7px;
  background: #3c536c;
}
@media (min-width: 992px) {
.language-select-container {
    display: flex;
    padding: 0;
}
}