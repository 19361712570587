.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.navbar-top {
  display: block;
}
@media (min-width: 992px) {
.navbar-top {
    padding-top: 40px;
    position: relative;
}
.navbar-top::after {
    content: "";
    display: block;
    position: absolute;
    background: #d8d8d8;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 0;
}
}
.navbar-container-lg {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
.navbar-container-lg {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 960px;
}
}
@media (min-width: 1260px) {
.navbar-container-lg {
    max-width: 1200px;
}
}
.navbar-brand {
  padding-left: 15px;
  padding-bottom: 21px;
  max-width: 50%;
  width: 255px;
}
.navbar-brand img {
  width: 100%;
  max-width: none;
  height: auto;
}
@media (min-width: 992px) {
.navbar-brand {
    padding: 9px 0;
}
.navbar-brand img {
    max-height: none;
}
}
.nav-user {
  display: flex;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: 23px;
  transition: opacity 0.2s linear;
}
.nav-user span {
  cursor: pointer;
  display: block;
  margin-left: 26px;
}
.nav-user span:first-child {
  margin-left: 0;
}
.nav-user .nav-icon {
  color: #b0b0b0;
}
@media (min-width: 992px) {
.nav-user {
    display: none;
}
}
@media (min-width: 992px) {
.navbar-collapse {
    padding-top: 0;
    flex: 0 1 auto;
}
}
.navbar-user-logged-in .navbar-collapse {
  margin-top: -90px;
  position: relative;
  z-index: 10;
}
@media (min-width: 992px) {
.navbar-user-logged-in .navbar-collapse {
    margin-top: 0;
    position: static;
}
}
.navbar-user-logged-in .navbar-brand {
  transition: opacity 0.2s linear;
}
.navbar-user-logged-in.navbar-collapse-show .navbar-brand {
  opacity: 0;
}
@media (min-width: 992px) {
.navbar-user-logged-in.navbar-collapse-show {
    transition: none;
    opacity: 1;
}
}
.navbar-top-bar {
  background-color: #2e4159;
  font-weight: 500;
  position: relative;
  width: 100%;
}
@media (min-width: 992px) {
.navbar-top-bar {
    position: absolute;
    top: 0;
    left: 0;
}
.navbar-top-bar > .container {
    display: flex;
    justify-content: space-between;
}
}
.navbar-light .navbar-top-bar .navbar-nav .nav-link {
  color: rgb(255, 255, 255);
  transition: color 0.2s linear;
}
.navbar-light .navbar-top-bar .navbar-nav .nav-link:hover, .navbar-light .navbar-top-bar .navbar-nav .nav-link:focus, .navbar-light .navbar-top-bar .navbar-nav .nav-link.active {
  color: #fbfbfb;
}
.navbar-light .navbar-top-bar .navbar-nav .active > .nav-link {
  color: #fbfbfb;
}
@media (min-width: 360px) {
.nav-container {
    padding-left: 45px;
    padding-right: 45px;
}
}
@media (min-width: 992px) {
.nav-container {
    padding: 0;
}
}
.nav-user .fa-2x {
  font-size: 2rem;
}