.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.footer {
  color: #fbfbfb;
  position: relative;
  width: 100%;
  background: #2e4159;
  border-radius: 0;
  margin-bottom: 0;
}
.footer .col-12 {
  padding-bottom: 15px;
}
@media (min-width: 992px) {
.footer {
    padding-top: 45px;
}
.footer .col-12 {
    padding-bottom: 0;
}
}
.footer a {
  color: rgb(255, 255, 255);
}
.footer a:hover, .footer a:focus {
  color: #fff;
}
.footer .column-headline {
  color: #fbfbfb;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4375;
  letter-spacing: 1.6px;
  margin-bottom: 15px;
  margin-top: 45px;
  text-align: center;
  position: relative;
}
.footer .column-headline .text {
  display: inline-block;
  min-width: 50%;
  background: #2e4159;
  position: relative;
  z-index: 1;
  padding: 0 10px;
}
.footer .column-headline:before {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  margin-top: -1px;
  background: #fff;
  opacity: 0.45;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 0;
}
@media (min-width: 992px) {
.footer .column-headline {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 41px;
    text-align: left;
}
.footer .column-headline:before, .footer .column-headline:after {
    display: none;
}
.footer .column-headline .text {
    display: inline-block;
    min-width: auto;
    padding: 0;
}
}
.footer .logo-headline {
  margin-top: 0;
  min-height: 0;
  margin-bottom: 20px;
  text-transform: none;
}
.footer .logo-headline:before, .footer .logo-headline:after {
  display: none;
}
.footer .logo-headline .text {
  background: transparent;
}
@media (min-width: 992px) {
.footer .logo-headline {
    margin-bottom: 0;
    min-height: 31px;
}
}
.footer .headline-about {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
.footer .headline-about {
    margin-bottom: 0;
}
}
@media (min-width: 992px) {
.footer .headline-links {
    margin-bottom: 0;
    min-height: 50px;
}
}
.footer .headline-contact {
  margin-bottom: 25px;
}
@media (min-width: 992px) {
.footer .headline-contact {
    margin-bottom: 0;
    min-height: 50px;
}
}
.footer .link-footer-s {
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.9px;
  margin-bottom: 35px;
  color: rgb(255, 255, 255);
  text-align: center;
}
.footer .link-footer-s:last-child {
  margin-bottom: 0;
}
.footer .link-footer-s .link-icon {
  color: #fff;
  width: 19px;
  height: 19px;
  margin-bottom: 5px;
}
.footer .link-footer-s .link-text {
  color: #fbfbfb;
  text-align: center;
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 1.166667;
}
@media (min-width: 992px) {
.footer .link-footer-s {
    display: flex;
    margin-bottom: 7px;
}
.footer .link-footer-s .link-icon {
    margin-right: 12px;
    margin-bottom: 0;
    width: 16px;
    height: 16px;
}
.footer .link-footer-s .link-text {
    text-align: left;
    line-height: 1.5;
}
}
.footer .footer-text {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 29px;
}
@media (min-width: 380px) {
.footer .footer-text {
    padding-left: 30px;
    padding-right: 30px;
}
}
@media (min-width: 992px) {
.footer .footer-text {
    text-align: left;
    padding: 0;
    margin-bottom: 20px;
}
}
.footer .footer-contact {
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.9px;
  line-height: 1.166667;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}
.footer .footer-links {
  flex-direction: column;
  position: relative;
  font-size: 0.75rem;
  line-height: 1.166667;
  letter-spacing: 0.9px;
  text-align: center;
}
.footer .footer-links .nav-item {
  margin-bottom: 20px;
}
.footer .footer-links .nav-item:last-child {
  margin-bottom: 0;
}
.footer .footer-links .nav-link {
  font-weight: 500;
  color: #fbfbfb;
  padding: 10px;
  text-transform: uppercase;
}
.footer .footer-links .nav-link:hover, .footer .footer-links .nav-link:focus {
  text-decoration: underline;
}
@media (min-width: 992px) {
.footer .footer-links {
    flex-direction: row;
    text-align: left;
    font-size: 0.625rem;
    line-height: 1.2;
    letter-spacing: 2px;
    margin-bottom: 0;
    padding-top: 42px;
}
.footer .footer-links .nav-item {
    margin-bottom: 0;
}
.footer .footer-links .nav-item:first-child .nav-link {
    padding-left: 0;
}
.footer .footer-links .nav-link {
    font-weight: 400;
    text-transform: none;
    padding-bottom: 17px;
}
}
.footer .footer-logos {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer .footer-logos .logo {
  border-right: 1px solid #fafafa;
  flex: 0 0 auto;
  padding: 0 10px;
}
.footer .footer-logos .logo:last-child {
  border-right-width: 0;
}
.footer .footer-logos img {
  width: 90px;
  height: auto;
}
@media (min-width: 992px) {
.footer .footer-logos {
    align-items: flex-start;
    justify-content: flex-start;
}
}
.footer .copyright {
  padding: 20px 0 36px 0;
  margin: 0;
  text-align: center;
  font-size: 0.625rem;
  line-height: 1.2;
  letter-spacing: 2px;
  color: #fbfbfb;
}
@media (min-width: 992px) {
.footer .copyright {
    padding-top: 52px;
    padding-bottom: 10px;
    text-align: right;
}
}
.footer .copyright-company {
  text-transform: uppercase;
}